<template>
  <div>
    <b-table :items="info" :fields="fields" responsive no-header striped sticky-header="95vh">
    
      <template #cell(Fecha_Identificada)="data">
        <div class="date-class">
          <span>{{ formatearFecha(data.item.Fecha_Identificada) }}</span>
        </div>
      </template>
      <template #cell(Fecha_Requerida)="data">
        <div class="date-class">
          <span>{{ formatearFecha(data.item.Fecha_Requerida) }}</span>
        </div>
      </template>
      <template #cell(Fecha_Comprometida)="data">
        <div class="date-class">
          <span>{{ formatearFecha(data.item.Fecha_Comprometida) }}</span>
        </div>
      </template>
      <template #cell(Fecha_Liberada)="data">
        <div class="date-class">
          <span>{{ formatearFecha(data.item.Fecha_Liberada) }}</span>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment-timezone';

export default {
  props: {
    datos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      firstRow: [],
      info:[],
      fields: [
        { key: 'ID_Restriccion', label: 'ID_Restriccion', sortable: false },
        { key: 'Especialidad', label: 'Especialidad', sortable: false },
        { key: 'Dueño', label: 'Dueño', sortable: false },
        { key: 'Descripcion', label: 'Descripcion', sortable: false },
        { key: 'Detalle', label: 'Detalle' },
        { key: 'Prioridad', label: 'prioridad' },
        { key: 'Categoria', label: 'categoria' },
        { key: 'Fecha_Identificada', label: 'fecha_identificada' },
        { key: 'Fecha_Requerida', label: 'fecha_requerida' },
        { key: 'Fecha_Comprometida', label: 'fecha_comprometida' },
        { key: 'Fecha_Liberada', label: 'fecha_liberada' },
        { key: 'Estado', label: 'Estado' },
        { key: 'Condicion', label: 'Condicion' },
        { key: 'Tipo', label: 'Tipo' },
      ],
    };
  },
  created() {
  },
  mounted(){
  
    let auxHeader = [];   
    if (this.datos.length > 0) {
      this.datos.map((key,index)=>{
        console.log({key})
        console.log({index})
        if(index ==0){
          auxHeader = key;
         
        }else {
          const obj1 = {};
          auxHeader.forEach((element,i) => {   
           
            obj1[element] = key[i];
            
          });
          this.info.push(obj1);
        }      
      })
   
      
    }
  },
  methods: {
    // Función para formatear una fecha
    formatearFecha(fecha) {
      console.log({fecha})
      if (fecha == '' || fecha == null ) {
        return ''; 
      }    

      if(typeof(fecha)=='string')  {       
        return fecha;
      }
   
      // Parsea la fecha con moment-timezone y ajusta a la zona horaria de Perú
      const fechaAjustada = moment.tz(fecha, 'America/Lima');

      // Agrega un día a la fecha ajustada
      const fechaCorregida = fechaAjustada.add(1, 'day');

      // Formatea la fecha como "DD/MM/YYYY"
      return fechaCorregida.format('DD/MM/YYYY');
    },
  },
};
</script>

<style lang="scss">
  .date-class {
    width: 100px;
  }
</style>
